import React from "react";
import DashboardCard, { Percentage } from "./card";
import { formatCurrency } from "../../../common/helpers/formatter";
import { useGeckoCoinApi } from "../../../common/hooks/useGeckoCoinApi";

type Props = {
  coin: string;
};

const CoinPrice: React.FC<Props> = ({ coin }) => {
  const coinId = coin.toLocaleLowerCase();
  const { data } = useGeckoCoinApi(coinId);
  const formattedPrice = formatCurrency(data?.priceUsd, "usd", "de-DE");
  const Image = () => <img src={data?.image} alt={data?.name} />;

  return (
    <DashboardCard
      title={`${data?.name}`}
      value={formattedPrice}
      extra={<Percentage percentage={data?.change24h} />}
      icon={<Image />}
      href={`https://www.coingecko.com/en/coins/${coinId}`}
    />
  );
};

export default CoinPrice;
