export const normalize = (data: any): CoinData => {
    const { market_data } = data;
    const priceUsd = market_data?.current_price.usd;
    const change24h: number = market_data?.price_change_percentage_24h;
    const change7d: number = market_data?.price_change_percentage_7d;
    const change30d: number = market_data?.price_change_percentage_30d;
    const athDistance = (priceUsd / market_data?.ath?.usd - 1) * 100;
    
    return {
      image: data?.image?.small || "",
      name: data?.name,
      priceUsd,
      marketCap: market_data?.market_cap.usd,
      change24h,
      change7d,
      change30d,
      athUsd: data?.market_data?.ath?.usd,
      athDistance,
      id: data.id,
      others: data
    };
}