import React, { ComponentProps, ReactNode } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { formatPercent } from "../../../common/helpers/formatter";
import Card from "../../UI-Elements/Card";

type Props = {
  value?: string;
  extra?: ReactNode;
  description?: string;
  icon?: ReactNode;
} & ComponentProps<typeof Card>;

export const Percentage: React.FC<{ percentage?: number; extra?: string }> = ({ percentage = 0, extra }) => {
  const color = percentage > 0 ? "text-green-500" : "text-red-500";
  const formatted = formatPercent(percentage);
  const icon = percentage > 0 ? <ArrowUpIcon className="h-4 w-4" /> : <ArrowDownIcon className="h-4 w-4" />;

  if (percentage === null) return <div className={`flex items-center text-sm`}>-</div>;

  return (
    <div className={`flex items-center text-sm font-semibold ${color}`}>
      {extra} {formatted} {icon}
    </div>
  );
};

const DashboardCard: React.FC<Props> = ({ title, value = "-", extra, description, icon, href, children, ...others }) => {
  return (
    <Card title={title} href={href} {...others}>
      <div className="flex items-center">
        <div className="flex-1">
          <div className="text-2xl">{value}</div>
          {children}
          {extra && <div className="flex items-center">{extra}</div>}
          {description && <div className={`flex items-center text-sm font-semibold`}>{description}</div>}
        </div>
        <div className="not-prose">{icon}</div>
      </div>
    </Card>
  );
};

export default DashboardCard;
