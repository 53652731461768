import { useEffect, useState } from "react";
import { isDev } from "../helpers/utils";

export const useApi = <T>(url: string, normalizer?: (res: any) => T) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    fetch(url)
      .then((res) => res.json())
      .then((response) => {
        if (isDev) console.log(response);

        normalizer ? setData(normalizer(response)) : setData(response);
      })
      .catch((error) => {
        if (isDev) console.log(error);
        setError(error)
      })
      .finally(() => setLoading(false));
  }, [url]);

  return { data, loading, error };
};

export const useApiList = <T>(urls: string[], normalizer?: (res: any) => T) => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);  

  useEffect(() => {
    setLoading(true);
    setError(false);
    setLoading(true);
    const promises = urls.map((url) => fetch(url).then((res) => res.json()));
    Promise.all(promises)
      .then((response) => {
        const normalizedData: any = normalizer ? response.map(normalizer) : response;
        if (isDev) console.log(normalizedData);

        setData(normalizedData)
      })
      .catch((error) => {
        if (isDev) console.log(error);
        setError(error)
      })
      .finally(() => setLoading(false));
  }, []);

  return { data, loading, error };
};
