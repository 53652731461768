/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const createFormatter = (currency = "usd", locale = "en-US") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency.toLocaleUpperCase(),
  });
};

export const formatCurrency = (value = 0, currency?: string, locale = "en-US", trailingZeros = true) => {
  const formatter = createFormatter(currency, locale);
  return trailingZeros ? formatter.format(value) : formatter.format(value).replace(/,00/, "");
};

export const formatPercent = (value: number | string | null): string => {
  if (!value || value == null) return "- %";

  const fixed = Number.parseFloat(String(value)).toFixed(2).toString();
  return `${fixed} %`;
};

export const formatLanguage = (value: string): string => {
  switch (value) {
    case "german":
      return "Deutsch";
    case "english":
      return "English";
    default:
      return "";
  }
};