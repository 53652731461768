import React from "react";
import Layout from "@components/layout";
import SEO from "@components/seo";
import Link from "@components/UI-Elements/Navigation/Link";
import CoinPrice from "@components/Modules/dashboard/coin-price";
import Card from "@components/UI-Elements/Card";
import { SiInstagram } from "@react-icons/all-files/si/SiInstagram";
import { SiFacebook } from "@react-icons/all-files/si/SiFacebook";
import { SiTwitter } from "@react-icons/all-files/si/SiTwitter";
import {
  facebookLink,
  instagramLink,
  twitterProfileLink,
} from "../common/helpers/config";
import { StaticImage } from "gatsby-plugin-image";
import Title from "@components/UI-Elements/Typography/Title";
import { Section } from "@components/UI-Elements/General";
import {
  dataChartsServices,
  filterByLanding,
  knowledgeServices,
} from "@common/data/content/service-cards";
import ServiceCardList from "@components/UI-Elements/Cards/ServiceCardList";
import SectionHeader from "@components/UI-Elements/General/SectionHeader";
import { Url } from "@common/config/url-builder";

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["startseite"]} title="Start" />

      <Section className="flex flex-col-reverse md:flex-row">
        <div className="px-10 md:w-1/2">
          <StaticImage
            src="../images/undraw_nakamoto.svg"
            alt="Drawing with a guy and bitcoin logo"
            className="mx-auto mb-8 block"
          />
        </div>
        <div className="order-1 text-center md:w-1/2">
          <Title>Willkommen auf einfachcrypto.de</Title>

          <p className="prose dark:prose-invert text-lg leading-loose">
            Auf dieser Seite sammel ich{" "}
            <Link to={Url.knowledge.index} className="font-bold no-underline">
              nützliche Information
            </Link>{" "}
            über Bitcoin und Kryptowährungen. Dazu gehören auch{" "}
            <Link to={Url.dashboard.index} className="font-bold no-underline">
              Tools und Dashboards
            </Link>{" "}
            und für Krypto-Investoren. Weitere Inhalte von mir findest du auf{" "}
            <Link href={instagramLink} className="font-bold" external>
              Instagram.
            </Link>
          </p>
        </div>
      </Section>

      <ServiceCardList
        tag="Wissen"
        tagProps={{ color: "amber" }}
        title="Steige tiefer ins Thema ein"
        subtitle="Lerne den Kaninchenbau (Bitcoin) und andere Kryptowährungen kennen."
        services={knowledgeServices.filter(filterByLanding)}
        buttonTitle="Weitere Infos"
        serviceColor="bg-orange-500"
        buttonProps={{ to: Url.knowledge.index }}
      />

      <ServiceCardList
        tag="Daten & Charts"
        tagProps={{ color: "blue" }}
        title="Was sagen die Daten?"
        subtitle="Diese Dashboards and Tools, sollen für ein besseres Verständnis über Bitcoin und den Markt sorgen."
        services={dataChartsServices.filter(filterByLanding)}
        buttonTitle="Weitere Tools"
        serviceColor="bg-blue-600"
        buttonProps={{ to: Url.dashboard.index, color: "default", }}
      />

      <Section className="pt-10 pb-24">
        <Title level={2}>Aktuelle Preise</Title>

        <div className="my-5 grid gap-4 md:grid-cols-3">
          <CoinPrice coin="bitcoin" />
          <CoinPrice coin="ethereum" />
          <CoinPrice coin="solana" />
        </div>
      </Section>

      <Section className="flex flex-col py-10 md:flex-row">
        <div className="mt-5 text-center md:w-2/3">
          <SectionHeader
            tag="Community"
            tagProps={{ color: "violet" }}
            title="Lass uns vernetzen"
            subtitle="Werde Teil der Community und folge mir auf Instagram, Twitter oder Facebook."
          />

          <Card>
            <p>
              Weitere Inhalte findest du auf Instagram, Facebook und Twitter.
              Folge mir dort, um keine neuen Beiträge zu verpassen.
            </p>
            <div className="flex justify-center p-3">
              <Link
                href={instagramLink}
                external
                className="p-2 text-3xl hover:text-green-500"
              >
                <SiInstagram />
              </Link>
              <Link
                href={facebookLink}
                external
                className="p-2 text-3xl hover:text-green-500"
              >
                <SiFacebook />
              </Link>
              <Link
                href={twitterProfileLink}
                external
                className="p-2 text-3xl hover:text-green-500"
              >
                <SiTwitter />
              </Link>
            </div>
          </Card>
        </div>

        <div className="px-10 md:w-1/3">
          <StaticImage
            src="../images/landingpage/instagram-iphone-light.png"
            alt="Einfach Crypto auf Instagram"
            className="mx-auto block max-w-xs"
          />
        </div>
      </Section>
    </Layout>
  );
};

export default IndexPage;
